#header {
  position: fixed;
  top: 0;
  opacity: 0.95; /* add */
}
#left-column {
  overflow: auto;
  min-width: 200px;
}
#left-column ul {
  list-style-type: none;
}
#left-column li {
  font-size: large;
}
.simplebar-scrollbar::before {
  background: linear-gradient(darkblue, skyblue);
}
