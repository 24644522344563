img.logo {
    margin-bottom: -10px;
    margin-right: 20px;
}

h1.header {
    margin-top: 20px;
}

hr.koji {
	background-color: #FF0;
	border-top: 10px dashed #000;
    width: 150%;
}

.box {
  border: solid 2px black;
  padding: 15px;
  width: 100%;
  border-radius: 10px; /* 角を丸くする */
}

h3.anchor{
  display: block;
  padding-top: 115px;
  margin-top: -115px;
}

.point_box {
  /* padding:4px 8px;font-size:13px;position:absolute;top:-15px;left:10px;background:#ff5a40;border:2px solid #fff;-moz-border-radius:16px;-webkit-border-radius:16px;border-radius:16px;color:#fff;line-height:1; */
  padding:3px 7px;font-size:13px;background:skyblue;border:2px solid #fff;-moz-border-radius:16px;-webkit-border-radius:16px;border-radius:16px;color:#444;line-height:1;
}

.blackboard-box {
  background: #104300;
  margin: 1em 0;
  padding: 1em 1em 0 1em;
  border: 8px solid #a60;
  box-shadow: 2px 2px 4px #999, 2px 2px 2px #020 inset;
}
  
.blackboard-box p {
  margin: 0;
  padding: 0;
  color: #fff;
  text-shadow: 0px 0px 2px #fff;
}
  
.chalk1 {
  display: block;
  margin-top: 10px;
  margin-left: 90%;
  border: solid 3px #fff;
  width: 15px;
  border-radius: 3px 2px 0 2px;
}
  
.chalk2 {
  display: block;
  margin-top: -6px;
  margin-left: calc(90% - 30px);
  border: solid 3px orange;
  width: 20px;
  border-radius: 3px 2px 0 2px;
}

span.warn {
  border-bottom: solid 2px orange;
}

h5 {
  padding: 0.5em;/*文字周りの余白*/
  color: #494949;/*文字色*/
  background: #fffaf4;/*背景色*/
  border-left: solid 5px #ffaf58;/*左線（実線 太さ 色）*/
}

table {
  width: 100%;
  border-collapse:separate;
  border-spacing: 0;
}

table th:first-child {
  border-radius: 5px 0 0 0;
}

table th:last-child {
  border-radius: 0 5px 0 0;
  border-right: 1px solid #3c6690;
}

th.a {
  text-align: center;
  color:white;
  background: linear-gradient(#829ebc,#225588);
  border-left: 1px solid #3c6690;
  border-top: 1px solid #3c6690;
  border-bottom: 1px solid #3c6690;
  box-shadow: 0px 1px 1px rgba(255,255,255,0.3) inset;
  width: 20%;
  padding: 10px 0;
}

th.b {
  text-align: center;
  color:white;
  background: linear-gradient(#829ebc,#225588);
  border-left: 1px solid #3c6690;
  border-top: 1px solid #3c6690;
  border-bottom: 1px solid #3c6690;
  box-shadow: 0px 1px 1px rgba(255,255,255,0.3) inset;
  width: 35%;
  padding: 10px 0;
}

th.c {
  text-align: center;
  color:white;
  background: linear-gradient(#829ebc,#225588);
  border-left: 1px solid #3c6690;
  border-top: 1px solid #3c6690;
  border-bottom: 1px solid #3c6690;
  box-shadow: 0px 1px 1px rgba(255,255,255,0.3) inset;
  width: 45%;
  padding: 10px 0;
}

table td {
  text-align: center;
  border-left: 1px solid #a8b7c5;
  border-bottom: 1px solid #a8b7c5;
  border-top:none;
  box-shadow: 0px -3px 5px 1px #eee inset;
  /* width: 25%; */
  padding: 10px 0;
}

table td:last-child {
  border-right: 1px solid #a8b7c5;
}

table tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

table tr:last-child td:last-child {
  border-radius: 0 0 5px 0;
}